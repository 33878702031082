* {
  @include box-sizing;
  line-height: 1.65;
}

html,
body {
  background-color: $bg-color;
  color: $default-color;
  margin: 0;
  padding: 0;
}

html {
  font-family: $sans-serif;
  font-size: 14px;
  overflow-y: scroll;

  @media (min-width: 600px) {
    font-size: 16px;
  }
}

body {
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $default-shade;
  font-family: $sans-serif;
  line-height: normal;
}

a {
  color: $blue;
  text-decoration: none;
}

blockquote {
  border-left: .25rem solid $grey-2;
  color: $grey-1;
  margin: .8rem 0;
  padding: .5rem 1rem;

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    padding: 0 5rem 0 1.25rem;
  }
}

img {
  display: block;
  margin: 0 0 1rem;
  max-width: 100%;
}

td {
  vertical-align: top;
}
