.pagination {
  border-top: .5px solid $grey-2;
  font-family: $sans-serif;
  padding-top: 2rem;
  position: relative;
  text-align: center;

  span {
    color: $default-shade;
    font-size: 1.1rem;
  }

  .top {
    @include transition(all .3s ease-out);
    color: $default-color;
    font-family: $sans-serif;
    font-size: 1.1rem;
    opacity: .6;

    &:hover {
      opacity: 1;
    }
  }

  .right-container {
    text-align: right;
  }

  .left-container {
    text-align: left;
  }

  .arrow {
    @include transition(all .3s ease-out);
    color: $default-color;
    position: absolute;

    &:hover,
    &:focus {
      opacity: .6;
      text-decoration: none;
    }
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
  }

  .fa-angle-left, .fa-angle-right {
    vertical-align: text-bottom;
  }
}
