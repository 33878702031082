// Colors
$bg-color: #eee8d5; //base02
$default-color: #555; //base
$default-shade: #073642; //base02
$default-tint: #657b83; //base00
$grey-1: #979797; //base 979797
$grey-2: #93a1a1; //base e5e5e5
$grey-3: #fdf6e3; //base
$white: #fff; //
$blue: #268bd2;
$shadow-color: rgba(0, 0, 0, .2);
$code-color: #dc322f; //red

// Fonts
$sans-serif: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
$monospaced: Menlo, Monaco, monospace;

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
     -moz-box-sizing: $type;
          box-sizing: $type;
}

@mixin transition($args...) {
  -webkit-transition: $args;
     -moz-transition: $args;
          transition: $args;
}
